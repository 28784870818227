<template>
  <div class="surface-section w-full p-6 md:w-6 md:p-8">
    <div class="mb-5">
      <div class="text-900 text-3xl font-medium mb-3">{{ getSocietyName }}</div>
    </div>
    <div>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <label for="email" :class="{'p-error':v$.email.$invalid && submitted}" class="block text-900 font-medium mb-2"><i class="fa-solid fa-envelope" /> {{ $t('email') }} *</label>
        <InputText id="email" v-model="v$.email.$model" type="text" :class="{'p-invalid':v$.email.$invalid && submitted}" class="w-full mb-3 p-3" />
        <div v-if="v$.email.$error && submitted" class="mb-2">
          <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
        </div>

        <label for="password" :class="{'p-error':v$.password.$invalid && submitted}" class="block text-900 font-medium mb-2"><i class="fa-solid fa-lock" /> {{ $t('password') }} *</label>
        <InputText id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" type="password" class="w-full mb-3 p-3" />
        <div v-if="v$.password.$error && submitted" class="mb-2">
          <span id="password-error" v-for="(error, index) of v$.password.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
        </div>

        <div class="flex align-items-center justify-content-between mb-6">
          <a @click="$router.push({name: 'ResetPassword'})" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">{{ $t('forgot') }}</a>
        </div>

        <Button :label="$t('login')" type="submit" icon="fa-regular fa-user" class="w-full p-3"></Button>
        <Button v-if="!getIsBrowserApp" :label="$t('changeSociety')" icon="fa-regular fa-building" class="w-full p-3 mt-2" @click="$router.push({ name: 'QrCodeScanner', query: { forceCamera: true }})"/>
      </form>
    </div>
<!--    <div style="position: fixed; bottom: 20px; left: 20px;">-->
<!--      <language-selection />-->
<!--    </div>-->
  </div>
  <div class="hidden md:block w-6 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/signin/signin.jpg') + ')' }"></div>
</template>

<script>
// import LanguageSelection from '@/components/LanguageSelection';
import Alert from '@/utils/Alert';
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import IsBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import {mapState} from "vuex";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [IsBrowserAppMixin],
  components : {
    // LanguageSelection,
  },
  data () {
    return {
      email: '',
      password: '',
      submitted: false
    }
  },
  computed: {
    ...mapState({
      authInfo: state => state.auth.authInfo
    }),
    getSocietyName () {
      return this.authInfo.societyName
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email requis', required),
        email: helpers.withMessage('Email non valide', email)
      },
      password: {
        required: helpers.withMessage('Mot de passe requis', required),
      },
    }
  },
  methods: {
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      const payload = {
        email: this.email,
        password: this.password
      }

      await this.$store.dispatch('auth/login', payload)
        .then(() => {
          this.$router.push({ name: 'Home' })
        }).catch((e) => {
          Alert.errorMessage(this, e?.response?.data[0]?.extensions.code ?? 'defaultLoginError')
          this.resetForm()
        })
    },
    resetForm () {
      this.password = ''
      this.submitted = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>
